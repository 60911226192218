<template>
  <page-content>
    <page-title heading="จัดการเอกสารประกอบการสอน"> </page-title>

    <b-row>
      <b-col cols="12">
        <b-row align-v="end" align-h="between">
          <b-col>
            <h5 class="mb-0">จัดการวีดีโอประกอบการสอน</h5>
          </b-col>
          <b-col class="text-right">
            <b-button variant="danger" @click="showModalVideoLesson = true"
              >เพิ่มวีดีโอประกอบการสอน</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <lesson-table
      ref="videoLessonTable"
      class="my-4"
      title="วีดีโอประกอบการสอน"
      :fields="videoLessonFields"
      :params="videoLessonTableParams"
      @edit="onEditVideoLesson"
      @delete="onDeleteVideoLesson"
    />

    <create-or-update-lesson
      :edit-data.sync="videoLessonEditData"
      title="วีดีโอประกอบการสอน"
      type="video"
      v-model="showModalVideoLesson"
      @create:success="refreshVideoTable"
      @update:success="refreshVideoTable"
    ></create-or-update-lesson>

    <delete-lesson
      title="วีดีโอประกอบการสอน"
      v-model="showModalDeleteVideoLesson"
      @delete:success="onDeleteLessonSuccess"
      :delete-data.sync="videoLessonDeleteData"
    ></delete-lesson>

    <b-row>
      <b-col cols="12">
        <b-row align-v="end" align-h="between">
          <b-col>
            <h5 class="mb-0">จัดการเอกสารประกอบการสอน</h5>
          </b-col>
          <b-col class="text-right">
            <b-button variant="danger" @click="showModalDocLesson = true"
              >เพิ่มเอกสารประกอบการสอน</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <lesson-document-table
      ref="documentLessonTable"
      class="my-4"
      title="เอกสารประกอบการสอน"
      :fields="docLessonFields"
      :params="docLessonTableParams"
      @edit="onEditDocLesson"
      @delete="onDeleteDocLesson"
    />

    <create-or-update-m-document
      :edit-data.sync="docLessonEditData"
      title="เอกสารประกอบการสอน"
      v-model="showModalDocLesson"
      @create:success="refreshDocTable"
      @update:success="refreshDocTable"
    ></create-or-update-m-document>

    <delete-lesson-document
      title="เอกสารประกอบการสอน"
      v-model="showModalDeleteDocLesson"
      @delete:success="onDeleteLessonDocSuccess"
      :delete-data.sync="docLessonDeleteData"
    ></delete-lesson-document>

    <!-- <b-row>
      <b-col cols="12">
        <b-row align-v="end" align-h="between">
          <b-col>
            <h5 class="mb-0">จัดการวีดีโอ</h5>
          </b-col>
          <b-col class="text-right">
            <b-button variant="danger" @click="showModalVideo = true"
              >เพิ่มวีดีโอ</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <video-table
      ref="videoTable"
      class="mt-4"
      :fields="videoFields"
      @edit="onEditVideo"
      @on-hide="onHideVideo"
      @delete="onDeleteVideo"
    />

    <create-or-update-video
      v-model="showModalVideo"
      :edit-data.sync="videoEditData"
      @create:success="onCreateVideoSuccess"
    ></create-or-update-video>

    <delete-video
      v-model="showModalDeleteVideo"
      @delete:success="onDeleteVideoSuccess"
      :delete-data.sync="videoDeleteData"
    ></delete-video>

    <b-row>
      <b-col cols="12">
        <b-row align-v="end" align-h="between">
          <b-col>
            <h5 class="mb-0">จัดการข่าวประชาสัมพันธ์</h5>
          </b-col>
          <b-col class="text-right">
            <b-button variant="danger" @click="showModalNews = true"
              >เพิ่มข่าว</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <news-table
      ref="newsTable"
      class="mt-4"
      :fields="newsFields"
      :params="newsTableParams"
      @edit="onEditNews"
      @delete="onDeleteNews"
    />

    <create-or-update-news
      :edit-data.sync="newsEditData"
      v-model="showModalNews"
      @create:success="onCreateNewsSuccess"
    ></create-or-update-news>

    <delete-news
      v-model="showModalDeleteNews"
      @delete:success="onDeleteNewsSuccess"
      :delete-data.sync="newsDeleteData"
    ></delete-news> -->
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import CreateOrUpdateLesson from "../../components/modal/CreateOrUpdateLesson";
import CreateOrUpdateMDocument from "../../components/modal/CreateOrUpdateMDocument.vue";
// import CreateOrUpdateNews from "../../components/modal/CreateOrUpdateNews";
// import CreateOrUpdateVideo from "../../components/modal/CreateOrUpdateVideo";
import LessonTable from "../../components/table/Lesson";
import LessonDocumentTable from "../../components/table/LessonDocument.vue";
// import NewsTable from "../../components/table/News";
// import VideoTable from "../../components/table/Video";
import DeleteLesson from "../../components/modal/DeleteLesson";
import DeleteLessonDocument from "../../components/modal/DeleteLessonDocument";
// import DeleteNews from "../../components/modal/DeleteNews";
// import DeleteVideo from "../../components/modal/DeleteVideo";
import { Auth } from "../../models";

export default {
  components: {
    PageContent,
    PageTitle,
    CreateOrUpdateLesson,
    CreateOrUpdateMDocument,
    // CreateOrUpdateNews,
    // CreateOrUpdateVideo,
    LessonTable,
    LessonDocumentTable,
    // NewsTable,
    // VideoTable,
    DeleteLesson,
    DeleteLessonDocument,
    // DeleteNews,
    // DeleteVideo,
  },

  data() {
    return {
      //   lessonKeyword: "",
      //   keyword: "",
      //   lessonFormfilter: {},
      //   newsFormfilter: {},
      videoLessonEditData: {},
      docLessonEditData: {},
      //   newsEditData: {},
      //   videoEditData: null,
      videoLessonDeleteData: {},
      docLessonDeleteData: {},
      //   videoDeleteData: {},
      //   newsDeleteData: {},
      showModalVideoLesson: false,
      showModalDocLesson: false,
      //   showModalNews: false,
      showModalDeleteVideoLesson: false,
      showModalDeleteDocLesson: false,
      //   showModalDeleteNews: false,
      //   showModalDeleteVideo: false,
      //   showModalVideo: false,
      docLessonTableParams: {},
      videoLessonFields: [
        {
          key: "lesson_no",
          label: "บทเรียนที่",
          sortable: true,
          class: "text-right",
        },
        {
          key: "lesson_order",
          label: "ลำดับที่",
          sortable: true,
          class: "text-right",
        },
        {
          key: "lesson_title",
          label: "ชื่อวีดีโอประกอบการสอน",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "วันที่สร้าง",
          sortable: true,
        },
        {
          key: "updatedAt",
          label: "แก้ไขล่าสุด",
          sortable: true,
        },
      ],
      docLessonFields: [
        {
          key: "title",
          label: "ชื่อเอกสาร",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "วันที่สร้าง",
          sortable: true,
        },
        {
          key: "updatedAt",
          label: "แก้ไขล่าสุด",
          sortable: true,
        },
      ],
      //   newsFields: [
      //     { key: "news_title", label: "หัวข้อข่าว", sortable: true },
      //     {
      //       key: "createdAt",
      //       label: "วันที่สร้าง",
      //       sortable: true,
      //     },
      //     {
      //       key: "updatedAt",
      //       label: "แก้ไขล่าสุด",
      //       sortable: true,
      //     },
      //   ],
      //   videoFields: [
      //     { key: "video_name", label: "ชื่อวีดีโอ", sortable: true },
      //     {
      //       key: "createdAt",
      //       label: "วันที่สร้าง",
      //       sortable: true,
      //     },
      //     {
      //       key: "updatedAt",
      //       label: "แก้ไขล่าสุด",
      //       sortable: true,
      //     },
      //   ],
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    videoLessonTableParams() {
      return {
        lesson_type: "video",
      };
    },

    // bannerTableParams() {
    //   const { bannerFormfilter } = this;

    //   return {
    //     ...bannerFormfilter,
    //   };
    // },

    // newsTableParams() {
    //   const { newsFormfilter } = this;

    //   return {
    //     ...newsFormfilter,
    //   };
    // },
  },

  methods: {
    // onKeywordSearched() {
    //   this.$set(this.formfilter, "keyword", this.keyword);
    // },

    // clearKeyword() {
    //   this.keyword = "";
    //   this.$delete(this.formfilter, "keyword");
    // },

    onEditVideoLesson(item) {
      this.videoLessonEditData = { ...item };
      this.showModalVideoLesson = true;
    },

    onEditDocLesson(item) {
      this.docLessonEditData = { ...item };
      this.showModalDocLesson = true;
    },

    // onEditNews(item) {
    //   this.newsEditData = { ...item };
    //   this.showModalNews = true;
    // },

    // onEditVideo(item) {
    //   this.videoEditData = { ...item };
    //   this.showModalVideo = true;
    // },

    refreshVideoTable() {
      this.$refs.videoLessonTable.refresh();
    },

    refreshDocTable() {
      this.$refs.documentLessonTable.refresh();
    },

    // onCreateNewsSuccess() {
    //   this.$refs.newsTable.refresh();
    // },

    // onCreateVideoSuccess() {
    //   this.$refs.videoTable.refresh();
    // },

    onDeleteVideoLesson(item) {
      this.videoLessonDeleteData = { ...item };
      this.showModalDeleteVideoLesson = true;
    },

    onDeleteDocLesson(item) {
      this.docLessonDeleteData = { ...item };
      this.showModalDeleteDocLesson = true;
    },

    // onDeleteNews(item) {
    //   this.newsDeleteData = { ...item };
    //   this.showModalDeleteNews = true;
    // },

    // onDeleteVideo(item) {
    //   this.videoDeleteData = { ...item };
    //   this.showModalDeleteVideo = true;
    // },

    onDeleteLessonSuccess() {
      this.$refs.videoLessonTable.refresh();
    },

    onDeleteLessonDocSuccess() {
      // this.$refs.documentLessonTable.refresh();
      window.location.reload();
    },

    // onDeleteNewsSuccess() {
    //   this.$refs.newsTable.refresh();
    // },

    // onDeleteVideoSuccess() {
    //   this.$refs.videoTable.refresh();
    // },

    // onHideVideo() {
    //   this.videoEditData = null;
    // },
  },
};
</script>
