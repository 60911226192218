<template>
  <b-form @submit.stop.prevent>
    <div class="d-flex flex-row flex-wrap">
      <div class="col-12 px-2 mb-2">
        <label class="font-weight-bold" for="fiscalYear">ชื่อเอกสาร</label>

        <b-form-input
          id="fiscalYear"
          :state="invalid ? validation.form.title.required : null"
          type="text"
          placeholder="เพิ่มชื่อเอกสาร"
          rows="2"
          max-rows="4"
          :disabled="disabled"
          v-model="form.title"
        ></b-form-input>

        <b-form-invalid-feedback id="fiscalYear-feedback">
          กรุณากรอกชื่อเอกสาร
        </b-form-invalid-feedback>
      </div>
    </div>

    <div class="d-flex flex-row flex-wrap">
      <div class="col-12 px-2 mb-2">
        <label class="font-weight-bold" for="fiscalYear">ลิงก์เอกสาร</label>

        <b-form-input
          id="fiscalYear"
          :state="invalid ? validation.form.url.required : null"
          type="text"
          placeholder="เพิ่มลิงก์เอกสาร"
          rows="2"
          max-rows="4"
          :disabled="disabled"
          v-model="form.url"
        ></b-form-input>

        <b-form-invalid-feedback id="fiscalYear-feedback">
          กรุณากรอกลิงก์เอกสาร
        </b-form-invalid-feedback>
      </div>
    </div>
  </b-form>
</template>

<script>
import { Auth } from "../../models";

export default {
  props: {
    formData: Object,

    editData: {
      type: Object,
      default: () => {},
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabledInput: {
      type: Object,
      default: () => ({}),
    },
    width: {
      type: Number,
      default: 1366,
    },
    height: {
      type: Number,
      default: 768,
    },
  },

  model: {
    prop: "formData",
    event: "change",
  },

  data() {
    return {
      isProcessing: false,
      form: {
        id: this.editData.id || null,
        title: this.editData.title || "",
        url: this.editData.url || null,
      },
    };
  },

  watch: {
    form: {
      deep: true,
      immediate: true,
      handler: "syncData",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  methods: {
    syncData(v) {
      this.$emit("change", v);
    },
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -8px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  background-color: #ffffff;
  border: 1px solid #3d6b99;
  border-radius: 50%;
  padding: 3px;

  &:focus {
    outline: none;
  }
}
.wrap-image {
  position: relative;
  width: 150px;
  height: 150px;

  > img {
    object-fit: cover;
  }
}
.btn-select-file {
  width: 104px;
  height: 104px;
  border: 1px dashed #d9d9d9;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:focus {
    outline: 1px dashed #d9d9d9;
  }
}
</style>
